<template>
	<div>
		<ContentHeader title="Kasir" url="/kasir" subTitle="Pembayaran" />
		<LoadingScreen v-if="loading_screen" />
		<div class="content-header">
			<div class="container-fluid">
				<!-- Component -->
				<DataPasienDanDokter />
				<!-- End COmponent -->
				
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header">
								<h3 class="card-title"><div>Pembayaran</div></h3>
							</div>
							<form @submit.prevent="prosesPembayaran">
								<div class="card-body">
									<div class="row">
										<div class="col">
											<div class="form-group">
												<label for="dokter">Jasa Medis  <span class="text-danger"> * </span> </label>
												<select class="custom-select form-control" id="dokter" required="" name="dokter" @change="tambahAdministrasiKeTransaksi(administrasiModel)" v-model="administrasiModel">
													<option selected hidden=""></option>
													<option v-for="(row, index) in administrasi" :key="row.id" v-bind:value="index">{{ row.kode_administrasi }} - {{ row.nama }}</option>
												</select>
												<p class="text-center" v-if="cek_data_administrasi">Administrasi Masih Kosong</p>
											</div>
										</div>
										<div class="col"></div>
									</div>
									<table class="table table-bordered table-hover">
									<thead>
										<tr>
										<th scope="col" class="text-sm">NO</th>
										<th scope="col" class="text-sm">NAMA TRANSAKSI</th>
										<th scope="col" class="text-sm">JENIS</th>
										<th scope="col" class="text-sm">TARIF</th>
										<th scope="col" class="text-sm">DISKON</th>
										<th scope="col" class="text-sm">ACTION</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(row, index) in transaksi" :key="row.id">
											<td>{{ increment(index) }}</td>
											<td >{{ row.nama }} x{{ row.jumlah }}</td>
											<td >{{ row.jenis_administrasi }}</td>
											<td >Rp. {{ number_format(row.harga, 0, ',', '.') }}</td>
											<td>
												<div class="row d-flex align-items-center">
													<div class="col-7">
														<div class="input-group">
														<div class="input-group-prepend">
															<div type="button" class="btn btn-secondary">{{ row.diskon.label }}</div>
														</div>
														<!-- /btn-group -->
														<input type="number" min="0" class="form-control" v-model="row.diskon.nilai" @keyup="hitungDiskon(index)" step="any">
														</div>
													</div>
													<div class="col-5" v-if="row.diskon.total == null || row.diskon.total == '' || row.diskon.total == undefined">
														
													</div>
													<div class="col-5" v-else-if="row.diskon.total == row.harga">
														<span class="text-success">Gratis</span>
													</div>		
													<div class="col-5" v-else-if="row.diskon.total > 0">
														<span>Tarif : <span class="text-success">Rp. {{ number_format(row.harga - row.diskon.total, 0, ',', '.') }}</span></span>
													</div>
												</div>
											</td>
											<td><button class="btn btn-block btn-outline-danger btn-xs" @click="hapusDataTransaksi(row.id)" v-if="row.hapus">Hapus</button></td>
										</tr>
									</tbody>
									</table>
									<p class="text-center" v-if="memuat">Memuat Data</p>
									<div class="row px-2 mt-3">
										<div class="col"></div>
										<div class="col">
											<div class="form-group">
												<label>Punya voucher?</label>
												<div class="row">
													<div class="col-8 d-flex">
														<input type="text" name="" placeholder="Masukkan kode voucher" class="form-control" v-model="voucher">
														<p class="btn btn-primary ml-2" @click="prosesDiskonVoucher">Gunakan</p>
														<el-popconfirm title="Hapus Voucher?" v-show="data_voucher" @confirm="resetVoucher">
															<template #reference>
															<el-button class="btn btn-danger ml-2" style="height: 20px;" type="danger">Hapus</el-button>
															</template>
														</el-popconfirm>	
													</div>
													<div class="col-4"></div>
												</div>
											</div>
											<div>
												<p>{{ status_voucher }}</p>
											</div>
											<div v-show="toggle">
												<p>Voucher senilai <span class="text-green">{{ format_nominal(nominalVoucher) }}</span></p>
												<p>Sub Total : <del>{{ subTotal }}</del></p>
											</div>
											<div v-show="toggleTidakTersedia">
												<p>Voucher Tidak Tersedia</p>
											</div>
											<div class="row align-items-center">
												<p class="col-6">Biaya Lainnya:</p>
												<div class="col-6">
													<div class="input-group mb-3">
													<div class="input-group-prepend">
														<div type="button" class="btn btn-secondary">Rp.</div>
													</div>
													<!-- /btn-group -->
													<input type="number" class="form-control" @keyup="totalBayar" v-model="transaksi_perawatan.biaya_lainnya" min="0">
													</div>
												</div>
											</div>
											<label for="dokter">Total Pembayaran</label>
											<div style="background-color: #343A40;color: white;" class="p-2 h1 text-right">
												<span style="font-family: 'Averia Serif Libre', cursive;">Rp. {{ number_format(total, 0, ',', '.') }}</span>
											</div>
											<div class="row align-items-center mt-2">
												<p class="col-6">Diskon Transaksi:</p>
												<div class="col-6">
													<div class="input-group">
														<div class="input-group-prepend">
															<div type="button" class="btn btn-secondary">{{ diskon_transaksi.label }}</div>
														</div>
														<!-- /btn-group -->
														<input type="number" min="0" class="form-control" v-model="diskon_transaksi.nilai" @keyup="totalBayar" step="any">
														</div>
												</div>
											</div>
											<label for="metode_pembayaran" class="mt-4">Metode Pembayaran</label>
											<div class="form-group text-left mt-2">
												<div class="row align-items-center">
													<p class="col-6">Tunai:</p>
													<div class="col-6">
														<div class="input-group mb-3">
														<div class="input-group-prepend">
															<div type="button" class="btn btn-secondary">Rp.</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" v-model="transaksi_perawatan.tunai" @keyup="prosesKembalian">
														</div>
													</div>
												</div>
												<div class="row align-items-center">
													<p class="col-3">Asuransi:</p>
													<el-select class="col-3 mb-3 ml-2 mr-n2" placeholder="Pilih Asuransi" id="asuransi" filterable clearable  name="asuransi" v-model="transaksi_perawatan.metode_pembayaran_asuransi" @change="paymentByAsuransi">
														<el-option selected="" hidden=""></el-option>
														<el-option v-for="(row, index) in dataLayanan" :key="index" :value="row.nama" :label="row.nama"></el-option>
													</el-select>
													<div class="col-6">
														<div class="input-group mb-3">
														<div class="input-group-prepend">
															<div type="button" class="btn btn-secondary">Rp.</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" v-model="transaksi_perawatan.asuransi" @keyup="prosesKembalian">
														</div>
													</div>
												</div>
												<div class="row align-items-center">
													<p class="col-3">Kartu Debit:</p>
													<el-select class="col-3 mb-3 ml-2 mr-n2" filterable clearable placeholder="Pilih Kartu Debit" id="metode_pembayaran_kartu_debit" name="metode_pembayaran_kartu_debit" v-model="transaksi_perawatan.metode_pembayaran_kartu_debit">
														<el-option selected="" hidden=""></el-option>
														<el-option value="BCA">BCA</el-option>
														<el-option value="BNI">BNI</el-option>
														<el-option value="BRI">BRI</el-option>
														<el-option value="MANDIRI">MANDIRI</el-option>
													</el-select>
													<div class="col-6">
														<div class="input-group mb-3">
														<div class="input-group-prepend">
															<div type="button" class="btn btn-secondary">Rp.</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" v-model="transaksi_perawatan.kartu_debit" @keyup="prosesKembalian">
														</div>
													</div>
												</div>
												<div class="row align-items-center">
													<p class="col-3">Kartu Kredit:</p>
													<el-select class="col-3 mb-3 ml-2 mr-n2" placeholder="Pilih Kartu Kredit" filterable clearable id="metode_pembayaran_kartu_kredit" name="metode_pembayaran_kartu_kredit" v-model="transaksi_perawatan.metode_pembayaran_kartu_kredit">
														<el-option selected="" hidden=""></el-option>
														<el-option value="BCA">BCA</el-option>
														<el-option value="BNI">BNI</el-option>
														<el-option value="BRI">BRI</el-option>
														<el-option value="MANDIRI">MANDIRI</el-option>
													</el-select>
													<div class="col-6">
														<div class="input-group mb-3">
														<div class="input-group-prepend">
															<div type="button" class="btn btn-secondary">Rp.</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" v-model="transaksi_perawatan.kartu_kredit" @keyup="prosesKembalian">
														</div>
													</div>
												</div>
												<div class="row align-items-center">
													<p class="col-3">Transfer:</p>
													<el-select class="col-3 mb-3 ml-2 mr-n2" placeholder="Pilih Transfer" filterable clearable id="metode_pembayaran_transfer" name="metode_pembayaran_transfer" v-model="transaksi_perawatan.metode_pembayaran_transfer">
														<el-option selected="" hidden=""></el-option>
														<el-option value="BCA">BCA</el-option>
														<el-option value="BNI">BNI</el-option>
														<el-option value="BRI">BRI</el-option>
														<el-option value="MANDIRI">MANDIRI</el-option>
														<el-option value="QRIS BCA">QRIS BCA</el-option>
														<el-option value="QRIS BRI">QRIS BRI</el-option>
														<el-option value="QRIS MADIRI">QRIS MANDIRI</el-option>
													</el-select>
													<div class="col-6">
														<div class="input-group mb-3">
														<div class="input-group-prepend">
															<div type="button" class="btn btn-secondary">Rp.</div>
														</div>
														<!-- /btn-group -->
														<input type="number" class="form-control" v-model="transaksi_perawatan.transfer" @keyup="prosesKembalian">
														</div>
													</div>
												</div>
												<div class="row align-items-center">
													<p class="col-6">Kembalian:</p>
													<div class="col-6">
														<div class="input-group mb-3">
														<div class="input-group-prepend">
															<div type="button" class="btn btn-secondary">Rp.</div>
														</div>
														<!-- /btn-group -->
														<p class="form-control">{{ number_format(kembalian, 0, ',', '.') }}</p>
														</div>
													</div>
												</div>
												<div class="row align-items-center">
													<p class="col-6">Kurang Bayar:</p>
													<div class="col-6">
														<div class="input-group mb-3">
														<div class="input-group-prepend">
															<div type="button" class="btn btn-secondary">Rp.</div>
														</div>
														<!-- /btn-group -->
														<p class="form-control">{{ number_format(transaksi_perawatan.kurang_bayar, 0, ',', '.') }}</p>
														</div>
													</div>
												</div>
												<div class="d-flex justify-centent-right mt-4">
													<button type="submit" class="btn btn-primary d-flex align-items-center mt-4 ml-auto">
														<div>Proses Pembayaran</div>	
														<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
														<span class="sr-only">Loading...</span>
														</div>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'
	import DataPasienDanDokter from '@/components/pelayanan/DataPasienDanDokter'
	import LoadingScreen from '@/components/LoadingScreen'
	
	import store from '@/store'
	import { computed, onMounted, ref, reactive } from 'vue'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import { useRoute } from 'vue-router'
	import router from '@/router'
	import number_format from '@/helper.js'
	import format_nominal from '@/format_nominal.js'
	import { debounce as _debounce } from "lodash"

	const route = useRoute()

	const user 		  = computed(() => store.getters['auth/user'])
	const cabangLogin = computed(() => store.getters['auth/cabang'])
	const memuat = ref(true)
	const loading_screen = ref(true)
	const kunjungan_id = route.params.id

	const { administrasi, administrasiModel, getAdministrasi, cek_data_administrasi } = useGetAdministrasi(user, kunjungan_id, cabangLogin)
	const { dataLayanan, getLayanan } = useLayanan()
	const loading = ref(false)


	// ---------Tambah Data Administrasi Ke Transaksi---------
	const transaksi = ref([])
	const administrasi_id = ref([])

	function tambahAdministrasiKeTransaksi (id) {
		let data = administrasi.value[id]
		let fee_dokter = 0
		
		if(data.jenis_administrasi == 'administrasi') {
			fee_dokter = 0
		} else {
			fee_dokter = data.fee_dokter
		}

		transaksi.value.push({
			'id' : id,
			'id_detail' : data.id,
			'nama' : data.nama,
			'jenis_administrasi' : data.jenis_administrasi,
			'harga' : data.harga,
			'jumlah' : 1,
			'fee_dokter' : fee_dokter,
			'fee_beautician' : 0,
			'diskon' : {
				'label' : '...',
				'nilai' : '',
				'total' : 0,
			},
			'point' : 0,
			'hapus' : true
		})

		administrasi_id.value.push(data.id)

		total.value = 0
		totalBayar()
	}

	const increment = (index) => {
		return index+1
	}
	
	// ---------End Tambah Data Administrasi Ke Transaksi---------

	// ---------Hapus Data Administrasi di Transaksi---------
	function hapusDataTransaksi (index) {

		for(let j = 0; j < transaksi.value.length; j++) {
			if (transaksi.value[j].id == index) {
				transaksi.value.splice(j, 1)
			}
		}
		administrasiModel.value = ''
		total.value = 0
		totalBayar()
	}
	// ---------End Hapus Data Administrasi di Transaksi---------

	const { voucher, subTotal, nominalVoucher, toggle, toggleTidakTersedia } = useProsesVoucher(total)

	// ---------Get data tindakan & resep obat---------
	const rekam_medis_id = ref()
	const tindakan_id = ref([])
	const resep_id = ref([])
	const detail_resep_obat = ref([])
	const detail_tindakan = ref([])
	const kembalian = ref(0)
	const transaksi_perawatan = reactive({
		"cabang_id" : user.value.cabang_id,
		"user_id" : user.value.id,
		"nomor_kwitansi" : '',
		"kunjungan_id" : kunjungan_id,
		"rekam_medis_id" : "",
		"pasien_id" : "",
		"tindakan_id" : tindakan_id.value,
		"resep_id" : resep_id.value,
		"dokter_id" : "",
		"administrasi_id" : administrasi_id.value,
		"voucher" : 0,
		"total_fee_dokter" : 0,
		"total_fee_beautician" : 0,
		"total_point" : 0,
		"tunai" : 0,
		"asuransi": 0,
		"metode_pembayaran_asuransi": null,
		"kartu_debit": 0,
		"metode_pembayaran_kartu_debit" : "",
		"kartu_kredit": 0,
		"metode_pembayaran_kartu_kredit" : "",
		"transfer": 0,
		"transferqris": 0,
		"metode_pembayaran_transfer" : "",
		"biaya_lainnya": 0,
		"total_bayar" : 0,
		"kembalian" : kembalian.value,
		"kurang_bayar" : 0,
		"detail" : []
	})

	const getRekamMedisId = async () => {
		let { data } = await axios.get(`api/rekam_medis/getWhereIdKunjungan/${kunjungan_id}/${cabangLogin.value.tipe}`)
		
		rekam_medis_id.value = data.id 
		transaksi_perawatan.rekam_medis_id = rekam_medis_id.value
		transaksi_perawatan.pasien_id = data.pasien_id
		transaksi_perawatan.dokter_id = data.dokter_id
		getDetailResepObat()
	}

	const getDetailResepObat = async () => {
		let { data } = await axios.get(`api/rekam_medis/getDetailResep/${rekam_medis_id.value}/${cabangLogin.value.tipe}`)

		if (data == 'kosong') {
			detail_resep_obat.value = []
			getDetailTindakan()
		} else {
			detail_resep_obat.value = data
			getDetailTindakan()
		}
	}

	const getDetailTindakan = async () => {
		let { data } = await axios.get(`api/rekam_medis/getDetailTindakan/${rekam_medis_id.value}/${cabangLogin.value.tipe}`)

		if (data == 'kosong') {
			detail_tindakan.value = []
			addDetailResepTindakanKeTransaksi()
		} else {
			detail_tindakan.value = data
			addDetailResepTindakanKeTransaksi()
		}
	}


	const addDetailResepTindakanKeTransaksi = async () => {
		if (detail_tindakan.value != null) {
			for (var i = 0; i < detail_tindakan.value.length; i++) {
				transaksi.value.push({
					'id' : null,
					'id_detail' : detail_tindakan.value[i].tindakan_id,
					'nama' : detail_tindakan.value[i].nama,
					'jenis_administrasi' : detail_tindakan.value[i].kategori,
					'harga' : detail_tindakan.value[i].harga_pasien_member,
					'jumlah' : detail_tindakan.value[i].jumlah,
					'fee_dokter' : detail_tindakan.value[i].fee_dokter,
					'fee_beautician' : detail_tindakan.value[i].fee_beautician,
					'fee_sales' : detail_tindakan.value[i].fee_sales,
					'diskon' : {
						'label' : '...',
						'nilai' : '',
						'total' : null,
					},
					'point' : detail_tindakan.value[i].point,
					'hapus' : false,
				})

				tindakan_id.value.push(detail_tindakan.value[i].id)
			}

			if(kunjungan.value?.poli?.nama?.toLowerCase() == 'khitan') {
				let total_administrasi_khitan = 0
				detail_tindakan.value.map((val) => {
					total_administrasi_khitan = parseInt(total_administrasi_khitan) + parseInt(val.harga_pasien_member * val.jumlah)
				})

				total_administrasi_khitan = parseInt((total_administrasi_khitan * 20) / 100)

				transaksi.value.push({
					'id' : null,
					'id_detail' : null,
					'nama' : 'Administrasi Khitan (20%)',
					'jenis_administrasi' : 'administrasi',
					'harga' : total_administrasi_khitan,
					'jumlah' : 1,
					'fee_dokter' : 0,
					'fee_beautician' : 0,
					'fee_sales' : 0,
					'diskon' : {
						'label' : '...',
						'nilai' : '',
						'total' : null,
					},
					'point' : 0,
					'hapus' : false,
				})
			}
			console.log(transaksi.value)
		}
		
		if (detail_resep_obat.value != null) {
			for ( i = 0; i < detail_resep_obat.value.length; i++) {
				let harga = 0
				harga = detail_resep_obat.value[i].harga * detail_resep_obat.value[i].jumlah
				transaksi.value.push({
					'id' : null,
					'id_detail' : detail_resep_obat.value[i].resep_atau_obat_id,
					'nama' : detail_resep_obat.value[i].nama,
					'jenis_administrasi' : detail_resep_obat.value[i].jenis,
					'harga' : harga,
					'jumlah' : detail_resep_obat.value[i].jumlah,
					'fee_dokter' : detail_resep_obat.value[i].fee_dokter,
					'fee_beautician' : 0,
					'fee_sales' : 0,
					'diskon' : {
						'label' : '...',
						'nilai' : '',
						'total' : null,
					},
					'point' : 0,
					'hapus' : false,
				})

				resep_id.value.push(detail_resep_obat.value[i].id)
			}
		}
		
		totalBayar()
		memuat.value = false
		loading_screen.value = false
	}
	// ---------End Get data tindakan & resep obat---------


	// Generate Nomor Kwitansi
	const kunjungan = ref([])
	const getKunjungan = async () => {
		let { data } = await axios.get(`api/kunjungan/getWhereId/${kunjungan_id}/${cabangLogin.value.tipe}`)

		kunjungan.value = data
		getCabang()
	}

	const cabang = ref([])
	const getCabang = async () => {
		let { data } = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

		cabang.value = data
		generateNomorKwitansi()
	}

	const generateNomorKwitansi = () => {
		transaksi_perawatan.nomor_kwitansi = `${cabang.value.kode_cabang}${kunjungan.value.nomor}`
	}
	// End Generate Nomor Kwitansi




	// Hitung Diskon
	const diskon = ref(0)
	const hitungDiskon = (index) => {
		diskon.value = 0

		if (transaksi.value[index].diskon.nilai != '') {
			diskon.value = parseInt(diskon.value)

			
			if (transaksi.value[index].diskon.nilai <= 100) {
				transaksi.value[index].diskon.label = 'Persen (%)'
				transaksi.value[index].diskon.total = (transaksi.value[index].harga * transaksi.value[index].diskon.nilai) / 100
			} else {
				transaksi.value[index].diskon.label = 'Rp.'
				transaksi.value[index].diskon.total = transaksi.value[index].diskon.nilai
			}


			for (let i = 0; i < transaksi.value.length; i++) {
				if (transaksi.value[i].diskon.total > 0) {
					diskon.value =  diskon.value + parseInt(transaksi.value[i].diskon.total)
				}
			}


			diskon.value = parseInt(diskon.value)
			totalBayar()	
		} else {
			transaksi.value[index].diskon.label = '...'
			transaksi.value[index].diskon.total = 0
			totalBayar()
		}
		
		
	} 
	// End Hitung Diskon

	// DISKON TOTAL TRANSAKSI
	const diskon_transaksi = reactive({
		'label' : '...',
		'nilai' : '',
		'total' : 0
	})
	// END DISKON TOTAL TRANSAKSI

	// const generateTotalBayar = () => {
	// 	total.value = totalAwal.value - diskon.value
	// }


	// ---------Total Bayar, uand diterima & kembalian---------
	const total = ref(0)
	const totalAwal = ref(0)
	const uangDiterima = ref()

	const totalBayar = () => {
		totalAwal.value = 0
		total.value = 0

		for (let k = 0; k < transaksi.value.length; k++) {
			if (parseInt(transaksi.value[k].diskon.nilai) > 0) {
				totalAwal.value = totalAwal.value + parseInt(transaksi.value[k].harga)
				total.value = total.value + (parseInt(transaksi.value[k].harga) - parseInt(transaksi.value[k].diskon.total)) 
				transaksi_perawatan.total_bayar = total.value
			} else {
				total.value = total.value + parseInt(transaksi.value[k].harga)
				transaksi_perawatan.total_bayar = total.value
				totalAwal.value = totalAwal.value + parseInt(transaksi.value[k].harga)
			}
		}
		
		if (transaksi_perawatan.biaya_lainnya != '') {
			totalAwal.value = totalAwal.value + parseInt(transaksi_perawatan.biaya_lainnya)
			total.value = total.value + parseInt(transaksi_perawatan.biaya_lainnya)
		}

		// diskon transaksi
		if (diskon_transaksi.nilai > 0) {
			if (diskon_transaksi.nilai <= 100 ) {
				diskon_transaksi.label = 'Persen (%)'
				diskon_transaksi.total = (total.value * diskon_transaksi.nilai) / 100
			} else {
				diskon_transaksi.label = 'Rp. '
				diskon_transaksi.total = diskon_transaksi.nilai
			}
			total.value = total.value - diskon_transaksi.total
		}
		// end diskon transaksi

		// Diskon Voucher
		if (data_voucher.value != null) {
			let diskon_voucher = 0
			if (data_voucher.value.nominal <= 100 ) {
				diskon_voucher = (total.value * data_voucher.value.nominal) / 100
			} else {
				diskon_voucher = data_voucher.value.nominal
			}
			total.value = total.value - diskon_voucher	
		}
		// End Diskon Voucher
	}

	const prosesKembalian = () => {

		uangDiterima.value = parseInt(transaksi_perawatan.tunai) + parseInt(transaksi_perawatan.kartu_debit) + parseInt(transaksi_perawatan.kartu_kredit) + parseInt(transaksi_perawatan.transfer)

		if (uangDiterima.value > total.value) {
			kembalian.value = Math.abs(total.value - uangDiterima.value)
			transaksi_perawatan.kurang_bayar = 0
		} else {
			kembalian.value = 0
			if(data_voucher.value != null) {
				transaksi_perawatan.kurang_bayar = Math.abs(total.value - uangDiterima.value)
			} 
		}
	}
	// ---------End Total Bayar, uand diterima & kembalian---------

	// Proses Voucher
	const status_voucher = ref('')
	const data_voucher = ref(null)
	const prosesDiskonVoucher = async () => {
		if (voucher.value != null) {
			status_voucher.value = 'Memuat Voucher' 
			let { data } = await axios.get(`api/voucher/getWhereKodeVoucher/${voucher.value}`)

			if (data == 'kosong') {
				status_voucher.value = 'Voucher tidak terssedia'
				data_voucher.value = null
			} else {
				data_voucher.value = data
				status_voucher.value = `Menggunakan Voucher Senilai ${format_nominal(data_voucher.value.nominal)}`
				totalBayar()
			}
			
		}
	}
	// End Proses Voucher

	// Reset Voucher
	const resetVoucher = () => {
		voucher.value = null
		data_voucher.value = null
		status_voucher.value = ''
		toggleTidakTersedia.value = false
		totalBayar()
	}
	// End Reset Voucher

	// Bayar dengan asuransi
	const paymentByAsuransi = () => {
		transaksi_perawatan.tunai = 0
		transaksi_perawatan.kartu_debit= 0
		transaksi_perawatan.metode_pembayaran_kartu_debit = ""
		transaksi_perawatan.kartu_kredit= 0
		transaksi_perawatan.metode_pembayaran_kartu_kredit = ""
		transaksi_perawatan.transfer= 0
		transaksi_perawatan.metode_pembayaran_transfer = ""
		transaksi_perawatan.biaya_lainnya= 0
		transaksi_perawatan.total_bayar = 0
		transaksi_perawatan.kurang_bayar = 0
		kembalian.value = 0
		
		transaksi_perawatan.asuransi = total.value 
	}
	// End Bayar dengan asuransi 


	// Proses Pembayaran
	const prosesPembayaran = _debounce( async() => {
		if (loading.value == false) {
			transaksi_perawatan.detail = transaksi.value
			transaksi_perawatan.diskon_transaksi = diskon_transaksi
			loading.value = true

			if (transaksi_perawatan.tunai == '') { transaksi_perawatan.tunai = 0 }
			if (transaksi_perawatan.kartu_debit == '') { transaksi_perawatan.kartu_debit = 0 }
			if (transaksi_perawatan.kartu_kredit == '') { transaksi_perawatan.kartu_kredit = 0 }
			if (transaksi_perawatan.transfer == '') { transaksi_perawatan.transfer = 0 }
			if (transaksi_perawatan.biaya_lainnya == '') { transaksi_perawatan.biaya_lainnya = 0 }

			transaksi_perawatan.total_fee_dokter = transaksi.value.reduce((total, row) => {
				if (row.fee_dokter > 0 && row.fee_dokter <= 100) {
					return  total + (row.harga * row.fee_dokter) / 100
				} else {
					return total + row.fee_dokter
				}

			}, 0)

			transaksi_perawatan.total_point = transaksi.value.reduce((total, row) => {

				return total + row.point

			}, 0)

			transaksi_perawatan.total_bayar = total.value
			transaksi_perawatan.voucher 	= data_voucher.value
			transaksi_perawatan.kembalian 	= kembalian.value
			
			axios.post('api/transaksi_perawatan/create', transaksi_perawatan)
			.then((response) => {
				Swal.fire({
					title: 'Berhasil!',
					text: 'Berhasil melakukan pembayaran',
					icon: 'success',
					showConfirmButton: false,
					timer: 1500
				})	
				loading.value = false
				router.push(`/print-transaksi-perawatan/${response.data.transaksi_id}`)
			})
			.catch(() => {
				Swal.fire({
					title: 'Gagal!',
					text: 'Gagal menambahkan data',
					icon: 'error',
					showConfirmButton: false,
					timer: 1500
				})	
				loading.value = false
			})
		}
	}, 200)
	// End Proses Pembayaran


	onMounted(() => {
		getAdministrasi()
		getRekamMedisId()
		getKunjungan()
		getLayanan()
	})


	function useGetAdministrasi(user, kunjungan_id, cabangLogin){
		// ---------Get Data Administrasi---------
		const administrasi = ref([])
		const administrasiModel = ref()
		const cek_data_administrasi = ref(false)
		const getAdministrasi = async () => {
			let response = await axios.get(`api/administrasi/getWherePoliByKunjunganId/${user.value.cabang_id}/${kunjungan_id}/${cabangLogin.value.tipe}`)

			if(response.data == 'kosong') {
				cek_data_administrasi.value = true
				administrasi.value = []
			} else {
				cek_data_administrasi.value = false
				administrasi.value = response.data
			}
		}

		// ---------End Get Data Administrasi---------

		return{
			administrasi, administrasiModel, getAdministrasi, cek_data_administrasi
		}
	}

	function useLayanan(){
		// LAYANAN
		const dataLayanan = ref([])

		const getLayanan = async () => {
			let response = await axios.get(`api/layanan/getWhereAktif`)

			if (response.data == 'kosong') {
				dataLayanan.value = []
			} else {
				dataLayanan.value = response.data
			}
		}
		// END LAYANAN
		return { dataLayanan, getLayanan }
	}


	function useProsesVoucher (total) {
		const voucher = ref(null)
		const subTotal = ref()
		const nominalVoucher = ref()
		const toggle = ref(false)
		const toggleTidakTersedia = ref(false)
		const totalSebelumnya = ref(0)

		const prosesVoucher = async () => {
			let response = await axios.get(`api/voucher/getWhereKodeVoucher/${voucher.value}`)
			
			if (response.data == 'kosong') {
				toggle.value = false
				toggleTidakTersedia.value = true	
				voucher.value = ''
			} else {
				toggle.value = true
				toggleTidakTersedia.value = false

				if (totalSebelumnya.value == 0) {
					totalSebelumnya.value = total.value
					nominalVoucher.value = response.data.nominal
					subTotal.value = total.value

					if (nominalVoucher.value <= 100) {
						let diskon = (total.value * nominalVoucher.value) / 100
						total.value = total.value - diskon
					} else {
						total.value = total.value - nominalVoucher.value
					}	
				} else {
					nominalVoucher.value = response.data.nominal
					subTotal.value = totalSebelumnya.value

					if (nominalVoucher.value <= 100) {
						let diskon = (totalSebelumnya.value * nominalVoucher.value) / 100
						total.value = totalSebelumnya.value - diskon
					} else {
						total.value = totalSebelumnya.value - nominalVoucher.value
					}
				}
			}
			
			
		}

		return {
			voucher,
			subTotal,
			nominalVoucher,
			toggle,
			toggleTidakTersedia,
			totalSebelumnya,
			prosesVoucher
		} 
	}



</script>

<style>
	@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@700&display=swap');
</style>