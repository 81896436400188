<template>
	<div class="row justify-content-center">
		<div class="col">
			<div class="card">
				<div class="card-header">
	               <h3 class="card-title"><div>Data Pasien &amp; Dokter Pemeriksa</div></h3>
	                <div class="card-tools">
	                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
	                    <i class="fas fa-minus"></i>
	                  </button>
	                </div>
	              </div>
        		<div class="card-body ">
        			<div class="row px-lg-2">
        				<div class="col">
        					<div class="form-group">
			                  <label for="nama">Nama</label>
			                  <input type="text" class="form-control" id="nama" name="nama" disabled="" v-model="kunjungan.nama_pasien">
			                </div>
			                <div class="form-group">
			                  <label for="jenis_kelamin">Jenis Kelamin</label>
			                  <input type="text" class="form-control" id="jenis_kelamin" name="jenis_kelamin" disabled="" v-model="kunjungan.jenis_kelamin">
			                </div>
			                <div class="form-group">
			                  <label for="waktu_kunjungan">Waktu Kunjungan</label>
			                  <input type="text" class="form-control" id="waktu_kunjungan" name="waktu_kunjungan" disabled="" v-model="kunjungan.waktu_kunjungan">
			                </div>
			                <div class="form-group">
			                  <label for="pooli">Poli</label>
			                  <input type="text" class="form-control" id="pooli" name="pooli" disabled="" v-model="kunjungan.nama_poli">
			                </div>
        				</div>
        				<div class="col">
        					<div class="form-group">
			                  <label for="umur">Umur</label>
			                  <input type="text" class="form-control" id="umur" name="umur" disabled="" v-model="kunjungan.tanggal_lahir">
			                </div>
			                <div class="form-group">
			                  <label for="dokter">Dokter</label>
			                  <input type="text" class="form-control" id="dokter" name="dokter" disabled="" v-model="kunjungan.nama_dokter">
			                </div>
			                <div class="form-group">
			                  <label for="waktu_kunjungan_berikutnya">Waktu Kunjungan Berikutnya</label>
			                  <input type="text" class="form-control" id="waktu_kunjungan_berikutnya" name="waktu_kunjungan_berikutnya" disabled="" v-model="kunjungan.waktu_kunjungan_berikutnya">
			                </div>
			                <div class="form-group">
			                  <label for="layanan">Layanan</label>
			                  <input type="text" class="form-control" id="layanan" name="layanan" disabled="" v-model="kunjungan.nama_layanan">
			                </div>
        				</div>
        			</div>
        		</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useRoute } from 'vue-router'
	import axios from 'axios'
	import { computed, onMounted, ref } from 'vue'
	import get_age  from '@/umur.js'
	import store from '@/store'
	// import * as dayjs from 'dayjs'

	const route = useRoute()
	const kunjungan_id = route.params.id
	const cabang = computed(() => store.getters['auth/cabang'])
	// Data Pasien

	const kunjungan = ref({
		nama_pasien: null,
		nama_dokter: null,
		jenis_kelamin: null,
		tanggal_lahir: null,
		waktu_kunjungan: null,
		waktu_kunjungan_berikutnya: null
	})

	const getRekamMedis = async () => {
		let response = await axios.get(`api/kunjungan/getWhereId/${kunjungan_id}/${cabang.value.tipe}`)
		console.log(response)
		if (response.data != null) {
			kunjungan.value.nama_pasien = response.data.pasien.nama
			kunjungan.value.nama_dokter = response.data.dokter.nama_lengkap
			kunjungan.value.waktu_kunjungan = response.data.tanggal
			kunjungan.value.waktu_kunjungan_berikutnya = response.data.kunjungan_berikutnya
			kunjungan.value.nama_poli = response.data.poli.nama
			kunjungan.value.nama_layanan = response.data.layanan.nama

			if (response.data.pasien.jenis_kelamin == 'L') {
				kunjungan.value.jenis_kelamin = 'Laki-laki'
			} else {
				kunjungan.value.jenis_kelamin = 'Perempuan'
			}

			if (response.data.pasien.tanggal_lahir != null) {
				kunjungan.value.tanggal_lahir = get_age(response.data.pasien.tanggal_lahir) + ' Tahun'
			}	
		}
	}

	onMounted(() => {
		getRekamMedis()
	})
</script>